import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Website Integration - Open IP Camera Manager",
  "path": "/Advanced_User/Website_Integration/Open_IP_Camera_Manager/",
  "dateChanged": "2017-09-26",
  "author": "Mike Polinowski",
  "excerpt": "Add your Cameras Image to your personal Homepage",
  "image": "./AU_SearchThumb_WebsiteIntegration_OIPCM.png",
  "social": "/images/Search/AU_SearchThumb_WebsiteIntegration_OIPCM.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_Integration_white.webp",
  "chapter": "Advanced User"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Website Integration - Open IP Camera Manager' dateChanged='2017-09-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='Add your Cameras Image to your personal Homepage' image='/images/Search/AU_SearchThumb_WebsiteIntegration_OIPCM.png' twitter='/images/Search/AU_SearchThumb_WebsiteIntegration_OIPCM.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Webseiten_Einbindung/Open_IP_Camera_Manager/' locationFR='/fr/Advanced_User/Website_Integration/Open_IP_Camera_Manager/' crumbLabel="Website" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "website-integration---open-ip-camera-manager",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#website-integration---open-ip-camera-manager",
        "aria-label": "website integration   open ip camera manager permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Website Integration - Open IP Camera Manager`}</h1>
    <h2 {...{
      "id": "snowman",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#snowman",
        "aria-label": "snowman permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Snowman`}</h2>
    <p><strong parentName="p">{`UPDATE`}</strong>{` The free software Open IP Camera Manager was superseded by the software `}<a parentName="p" {...{
        "href": "https://github.com/bernardladenthin/snowman"
      }}>{`Simple And Open Webcam Management`}</a>{`. Parts of the information below are still relevant for an installation on a Windows PC or Mac - for a detailed Installation Guide in Ubuntu follow this `}<a parentName="p" {...{
        "href": "https://github.com/bernardladenthin/snowman/wiki/Installation-guide"
      }}>{`Link`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      